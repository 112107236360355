module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather:ital,wght@0,400;0,700;0,900;1,700;1,900","Playfair+Display:ital,wght@1,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pastelería El Progreso","short_name":"El Progreso","description":"Don Juan Bautista Brignole, maestro pastelero de la tradicional Confiteria Del Molino, funda la Pasteleria El Progreso en la Av Santa Fé 2820. Hoy nietos y bisnietos siguiendo la tradición, continúan con la elaboración artesanal de sus recetas","start_url":"/","background_color":"#8D1A14","theme_color":"#000","display":"standalone","icon":"src/assets/icon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4b735a3f2d0b2bc04c3e24c173d3e26"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
